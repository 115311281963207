// import Headers from "@/components/headers"
import { getChargeInfo, postRecharge } from '@/api';
export default {
  data() {
    return {
      chosePayItem: 0,
      money: "",
      chargeList: [],
      moneyList: [],
      choseItem: null
    };
  },
  mounted() {
    getChargeInfo().then(res => {
      this.chargeList = res.data;
      const arr = Object.entries(res.data).map(([key, value]) => ({
        key,
        value
      }));
      this.chosePayItem = arr[0].key;
      this.getMoneyList(arr[0].value);
    });
  },
  methods: {
    chosePay(item) {
      this.chosePayItem = item.id;
      this.choseItem = item;
    },
    choseMoney(value) {
      this.money = value;
    },
    recharge() {
      if (!this.money) {
        this.$toast("请输入金额");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      postRecharge({
        money: this.money,
        id: this.chosePayItem
      }).then(res => {
        this.$closeToast();
        if (res.url) {
          console.log(res.url);
          window.open(res.url, '_blank');
          //   window.location.href = res.url
        } else {
          this.$toast(res.msg);
          this.$store.dispatch("getUserInfo");
        }
        this.money = "";
      });
    },
    getMoneyList(value) {
      this.moneyList = [500, 1000, 2000, 3000, 5000, 10000];
      this.moneyList = this.moneyList.filter(item => {
        return item >= value.min_money && item <= value.max_money;
      });
    }
  },
  watch: {
    choseItem(value) {
      this.getMoneyList(value);
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  components: {
    Headers
  }
};